const data = [
  {
    video: "https://www.youtube.com/embed/NnkaQbi18AQ",
    title: "Episode 1"
  },
  {
    video: "https://www.youtube.com/embed/hPwWHVptnFQ",
    title: "Episode 2"
  },
  {
    video: "https://www.youtube.com/embed/U6nvXuhBCCo",
    title: "Episode 3"
  },
  {
    video: "https://www.youtube.com/embed/yF-ZDkO5ESA",
    title: "Episode 4"
  },
  {
    video: "https://www.youtube.com/embed/-Lj25R6Nfi8",
    title: "Episode 5"
  },
  {
    video: "https://www.youtube.com/embed/wuZN3KS3li0",
    title: "Episode 6"
  },
  {
    video: "https://www.youtube.com/embed/KGzSsFMqK94",
    title: "Episode 7"
  },
  {
    video: "https://www.youtube.com/embed/pR-shm4WItI",
    title: "Episode 8"
  },
  {
    video: "https://www.youtube.com/embed/oyMKVmno0xQ",
    title: "Episode 9"
  },
  {
    video: "https://www.youtube.com/embed/TaEZ2ho4JhY",
    title: "Episode 10"
  }
]

export default data;
